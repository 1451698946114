import React from 'react'
import { graphql } from "gatsby"
import Layout from "../../../components/Layout";
import "../../../styles/odooServices.scss"
import "../../../styles/services.scss"
import "../../../styles/caseStudy.scss"
import News from '../../../components/News'
import CaseStudeiesTopSection from '../../../components/CaseStudeiesTopSection';
import CaseStudeiesSimplified from '../../../components/CaseStudeiesSimplified';
import CaseStudyDescription from '../../../components/caseStudyDescription';
import headerImage from '../../../images/caseStudies/MuscatMediaGroupoman.jpg';

const MuscatMGHRSolution = ({ data }) => {
  return (
    <div className='CaseStudeies'>
      <Layout pageTitle={"Vsiomate - HR Solution for Muscat Media Group"}
      descrip = {"Enhance employee engagement and productivity with our user-friendly HR software. From recruitment to performance management, create a positive workplace experience."}>
        <CaseStudeiesTopSection
          path='projects'
          // backgroundImage={backgroundImage}
          headerData={{
            title: "HR Management System tailored for Muscat Media Group built on Odoo Community Edition",
            peragraph: "All significant operations are covered in compliance with Oman's labor regulations. It covers passage management, cost tracking, computation of gratuities, recording of leaves and absences, resignation procedures, and an employee self-service portal. Organizations may simplify HR procedures, improve employee satisfaction, and assure compliance using automated workflows and digital solutions."
            , image: headerImage
          }}
          data={data}
        />
        <CaseStudyDescription
          caseStudyData={{
            ITitle: "Introduction",
            Idescription: "Since its founding in 1975, the Muscat Media Group (MMG) has been the leading media conglomerate in the Sultanate of Oman, significantly influencing Omani society. MMG offers advertisers unmatched reach and interaction opportunities with its wide array of over 32 Arabic and English language products, which includes flagship titles like the Times of Oman, Al Shabiba, and Tweekly.",

            image: headerImage,
            data: [

              {
                Title: "keyReqProblem",
                Point1: "MMG required a fully automated HR Management system mapped with their business needs.",
                Point2: "",
                Point3: ""
              },
              {
                Title: "Solution",
                Sdescription: "Visiomate helped the Muscat Media Group by providing them with a fully automated HR management solution on Odoo 13. Entailing features like attendance, leave management, graduity, Employee self portal, along with many others. Employee self service portal was one of the main components of this solution, helped MMG, allowing employees to see their salary slips, apply for leaves, manage their attendance and time/shift Management and check their leave balance as well",
                Point1: "Leave Management and Encashment",
                Point2: "Attendance Management and integration with Odoo",
                Point3: "Gratuity Management, Resignation process, Passage Management, Expense management, Employee self service portal",
              },
            ],
          }}
          data={data} />
        <CaseStudeiesSimplified
          TextData={{
            title: 'Approach that Achieved Success'
            ,
            Data: [
              {
                peragraph: 'Define Requirements',
                list: [
                  'Clearly outline your business objectives and identify the specific challenges or needs that the software solution should address.',
                  'List down the essential features and functionalities required.'
                ]
              },
              {
                peragraph: 'Demo and Evaluation',
                list: [
                  "Schedule product demonstrations. ",
                  "Evaluate the software's usability, features, and performance. ",
                  "Engage key stakeholders in the evaluation process."
                ]
              },
              {
                peragraph: 'Scalability and Integration',
                list: [
                  "Identify the potential integrations required by the customer.",
                  "Plan the implementation of identified integrations."
                ]
              },
              {
                peragraph: 'UAT & User Feedback',
                list: [
                  "UAT period allows customer to assess the system performance and suitability for the specific business needs.",
                  "Customer’s insights can provide valuable perspectives on usability, functionality mismatch."
                ]
              },
            ]
          }} />
        <News isAbout={true} career={true} />
      </Layout>
    </div >
  )
}

export default MuscatMGHRSolution
export const query = graphql`
  query MuscatMediaGroupomanImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsHeaderImg: file(
      relativePath: { eq: "caseStudies/MuscatMediaGroupoman.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondImg: file(
      relativePath: { eq: "caseStudies/MuscatMediaGroupoman.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
